import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import {
  MaterialReactTable,
  MRT_Row,
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
} from 'material-react-table';
import {
  Tooltip,
  IconButton,
  Box,
  Button,
  FormControlLabel,
  Checkbox,
  Chip,
  Autocomplete,
  TextField,
} from '@mui/material';
import { useSelector } from 'src/store/Store';
import { AppState } from 'src/store/Store';
import {
  QueryClient,
  QueryClientProvider,
  keepPreviousData,
  useQuery,
} from '@tanstack/react-query';
import axiosClient from 'src/axios_client';
import { Download, DownloadOutlined, Email, PlayArrow, PrintSharp } from '@mui/icons-material';
import { useReactToPrint } from 'react-to-print';
import 'src/styles/reports_print.css';
import {
  Table,
  Typography as TypographyAnt,
  Space,
  Image,
  Button as ButtonAnt,
  Tooltip as TooltipAnt,
  ConfigProvider,
  Row,
  Col,
} from 'antd';
import DateSelector from 'src/components/shared/AntDateSelector';
import dayjs, { Dayjs } from 'dayjs';
import useFormattedNumber from 'src/hooks/useFormattedNumber';
import Link from 'antd/es/typography/Link';
import { useNavigate } from 'react-router';
import { useTransaction } from 'src/contexts/PurchaseTransactionContext';
import LogoDark from 'src/assets/images/logos/dark-logo.svg';
import LogoLight from 'src/assets/images/logos/light-logo.svg';
import * as XLSX from 'xlsx';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { LoadingButton } from '@mui/lab';
import brandImage from 'src/assets/images/brand/rkc.png';

const { Title, Text } = TypographyAnt;
type SelectedCustomerSalesApiResponse = {
  customer_details: {
    id: number;
    name: string;
  };
  data: Array<SelectedCustomerSales>;
  pagination: {
    total: number;
    per_page: number;
    current_page: number;
    last_page: number;
    from: number;
    to: number;
  };
};

type SelectedCustomerSales = {
  id: number;
  name: string;
  code: string;
  purchase_count: string;
  total_amount: string;
  reference_number: string;
  slip_number: string;
  date_of_issuance: string;
};

type SelectedCustomerSalesTableProps = {
  customerId: number;
  dateRange: any;
};

const SelectedCustomerSalesTable: React.FC<SelectedCustomerSalesTableProps> = ({
  customerId,
  dateRange,
}) => {
  const [exporting, setExporting] = useState(false);
  const customizer = useSelector((state: AppState) => state.customizer);
  const currentTheme = customizer.activeMode;

  const [isDarkMode, setIsDarkMode] = useState(false);

  const { formatWithPrefix } = useFormattedNumber();

  const navigate = useNavigate();
  const { setTransactionId } = useTransaction();

  useEffect(() => {
    setIsDarkMode(currentTheme === 'dark');
  }, [currentTheme]);

  const normalizedDateRange = useMemo(() => {
    return dateRange.map((date: any) => {
      return dayjs.isDayjs(date) ? date.toISOString() : date;
    }) as [string, string];
  }, [dateRange]);

  console.log('CustomerID: ', customerId);
  console.log('dateRange: ', dateRange);

  useEffect(() => {
    if (!customerId) {
      console.warn('No transaction ID found, redirecting to purchase report');
      navigate('/report-sales-by-customer');
    }
  }, [customerId, navigate]);

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 100,
  });

  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

  // Define filter options
  const filterOptions = [
    { label: 'Unchecked', value: 'filter_checked' },
    { label: 'Unreleased', value: 'filter_released' },
    { label: 'Unreceived', value: 'filter_received' },
    { label: 'All Incomplete', value: 'filter_all_incomplete' },
    { label: 'Complete', value: 'filter_complete' },
  ];

  const {
    data: { data = [], pagination: meta, customer_details } = {},
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery<SelectedCustomerSalesApiResponse>({
    queryKey: [
      'selected-customer-purchase',
      customerId,
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
      dateRange,
      selectedFilters,
    ],
    queryFn: async () => {
      if (!customerId || !dateRange[0] || !dateRange[1]) {
        throw new Error('Customer ID and date range are required');
      }

      // Define the type for params to allow dynamic keys (string)
      const params: { [key: string]: string } = {
        page: `${pagination.pageIndex + 1}`,
        per_page: `${pagination.pageSize}`,
        filters: JSON.stringify(columnFilters ?? []),
        globalFilter: globalFilter ?? '',
        sorting: JSON.stringify(sorting ?? []),
        start_date: dateRange[0].toISOString(),
        end_date: dateRange[1].toISOString(),
      };

      // Add selected filters to params
      filterOptions.forEach((option) => {
        params[option.value] = selectedFilters.includes(option.value).toString();
      });

      try {
        // Make the API call with the customerId and params
        const response = await axiosClient.get<SelectedCustomerSalesApiResponse>(
          `/customer-sales-report/${customerId}`,
          { params },
        );

        return response.data;
      } catch (error) {
        console.error('Error fetching selected customer purchase report:', error);
        throw error;
      }
    },
    placeholderData: keepPreviousData,
    enabled: !!customerId && !!dateRange[0] && !!dateRange[1], // Only run the query if customerId and dateRange are available
  });

  const handleViewPurchaseEntryTransaction = (row: any) => {
    setTransactionId(row.id);
    navigate('/issuance-entry-view-transaction');
  };

  const customerName = customer_details?.name || '';

  const columns = useMemo<MRT_ColumnDef<SelectedCustomerSales>[]>(
    () => [
      {
        accessorKey: 'reference_number',
        header: 'Reference #',
        size: 180,
        enableColumnFilter: false,
        Cell: ({ renderedCellValue, row }) => {
          return (
            <Link onClick={() => handleViewPurchaseEntryTransaction(row.original)}>
              {renderedCellValue}
            </Link>
          );
        },
      },
      {
        accessorKey: 'slip_number',
        header: 'Slip #',
        size: 50,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'date_of_issuance',
        header: 'Issuance Date',
        grow: false,
        size: 50,
        Cell: ({ renderedCellValue, row }) => {
          return <span>{renderedCellValue}</span>;
        },
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => row.total_amount,
        id: 'total_amount',
        header: 'Total Amount',
        size: 100,
        Cell: ({ renderedCellValue, row }) => {
          const value = typeof renderedCellValue === 'number' ? renderedCellValue : 0;
          return <span>{formatWithPrefix(value)}</span>;
        },
        enableColumnFilter: false,
      },
      {
        accessorKey: 'incomplete',
        header: 'Status',
        size: 150,
        Cell: ({ cell }) => {
          const incomplete = cell.getValue<string[]>();
          if (incomplete.length === 0) {
            return <Chip label="Complete" color="success" size="small" />;
          } else {
            return <Chip label="Incomplete" color="error" size="small" />;
          }
        },
        enableColumnFilter: false,
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: { showColumnFilters: true, density: 'compact' },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    enableColumnOrdering: true,
    enableDensityToggle: false,
    enableRowSelection: true, 
    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
        {/* <DateSelector onDateChange={setDateRange} />

        <Tooltip arrow title="Extract Report">
          <Button
            onClick={handleRunReport}
            startIcon={
              <>
                <PlayArrow />
              </>
            }
          >
            Run Report
          </Button>
        </Tooltip> */}

        <Autocomplete
          multiple
          limitTags={2}
          id="filter-tags"
          options={filterOptions}
          getOptionLabel={(option) => option.label}
          value={filterOptions.filter((option) => selectedFilters.includes(option.value))}
          onChange={(event, newValue) => {
            setSelectedFilters(newValue.map((item) => item.value));
          }}
          renderTags={(value, getTagProps) =>
            value
              .slice(0, 2)
              .map((option, index) => <Chip key={option.value} label={option.label} />)
              .concat(value.length > 2 ? <span key="more">+{value.length - 2} more</span> : [])
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Filter Status"
              placeholder="Select filters"
            />
          )}
          sx={{ width: 500 }}
        />

        <Button onClick={handleExportData} startIcon={<DownloadOutlined />}>
          Export All Data
        </Button>

        <Button
          disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
          //only export selected rows

          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<DownloadOutlined />}
        >
          Export Selected Rows
        </Button>
      </Box>
    ),
    rowCount: meta?.total ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  const TEMPLATE_PATH = '/assets/etc/DocFormat.xlsx';

  const handleExportData = async () => {
    setExporting(true);
    try {
      const totalRows = meta?.total ?? 0;
      const pageSize = pagination.pageSize;
      const totalPages = Math.ceil(totalRows / pageSize);
      const allData: SelectedCustomerSales[] = [];

      // Fetch paginated data
      for (let pageIndex = 0; pageIndex < totalPages; pageIndex++) {
        const response = await axiosClient.get<SelectedCustomerSalesApiResponse>(
          `/customer-sales-report/${customerId}`,
          {
            params: {
              start_date: normalizedDateRange[0],
              end_date: normalizedDateRange[1],
            },
          },
        );
        allData.push(...response.data.data);
      }

      // Reuse handleExportRows logic for data export
      await handleExportRows(
        allData.map((data) => ({ original: data } as MRT_Row<SelectedCustomerSales>)),
      );
    } catch (error) {
      console.error('Error exporting data with ExcelJS:', error);
      alert('Failed to export data. Please try again.');
    } finally {
      setExporting(false);
    }
  };

  const handleExportRows = async (rows: MRT_Row<SelectedCustomerSales>[]) => {
    setExporting(true);
    try {
      // Load the template file
      const workbook = new ExcelJS.Workbook();
      const response = await fetch(TEMPLATE_PATH);
      const arrayBuffer = await response.arrayBuffer();
      await workbook.xlsx.load(arrayBuffer);

      // Access the first worksheet in the template
      const worksheet = workbook.getWorksheet(1);
      if (!worksheet) {
        throw new Error('Worksheet not found in the template file.');
      }

      // Adjust column widths
      worksheet.columns = [
        { width: 20, key: 'reference_number' },
        { width: 15, key: 'invoice_number' },
        { width: 35, key: 'date_of_purchase' },
        { width: 20, key: 'total_amount' },
      ];

      // Set title
      const titleCell = worksheet.getCell('F5');
      titleCell.value = `Customer Issuance Details - ${customerName}`;
      titleCell.font = { bold: true, size: 14 };
      titleCell.font = { bold: true, size: 14 };

      // Add today's date dynamically
      const dateCell = worksheet.getCell('F6');
      const today = new Date();
      const formattedDate = today.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
      dateCell.value = formattedDate;
      dateCell.font = { italic: true, size: 12 };

      const rangeCell = worksheet.getCell('H6');

      // Function to format dates as "Month XX, XXXX"
      const formatDate = (isoString: any) => {
        const date = new Date(isoString);
        return date.toLocaleDateString('en-US', {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        });
      };

      // Format the date range
      const startDate = formatDate(normalizedDateRange[0]);
      const endDate = formatDate(normalizedDateRange[1]);

      // Update the cell value with the formatted dates
      rangeCell.value = `Purchases from ${startDate} to ${endDate}`;
      rangeCell.font = { italic: true, size: 12 };

      // Define header and data start rows
      const headerRowIndex = 7;
      const dataStartRowIndex = 8;

      // Insert headers
      const headerRow = worksheet.getRow(headerRowIndex);
      headerRow.values = ['Reference #', 'Slip #', 'Date of Issuance', 'Total Amount'];
      headerRow.font = { bold: true };

      // Fetch the imported image for branding
      const imageResponse = await fetch(brandImage);
      const imageBuffer = await imageResponse.arrayBuffer();
      const imageId = workbook.addImage({
        buffer: imageBuffer,
        extension: 'png',
      });
      worksheet.addImage(imageId, {
        tl: {
          col: 0,
          row: 0,
          colOff: (10 * 9600) / 96,
          rowOff: (10 * 9600) / 96,
        } as any,
        ext: { width: 175, height: 40 },
        editAs: 'oneCell',
      });

      // Add data rows
      rows.forEach((row, index) => {
        const data = row.original;
        const currentRow = worksheet.getRow(dataStartRowIndex + index);

        currentRow.getCell(1).value = data.reference_number;
        currentRow.getCell(2).value = data.slip_number;
        currentRow.getCell(3).value = data.date_of_issuance;
        currentRow.getCell(4).value = data.total_amount;
        currentRow.commit();
      });

      // Save the modified Excel file
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, `${customerName}_IssuancesReport.xlsx`);
    } catch (error) {
      console.error('Error exporting rows with ExcelJS:', error);
      alert('Failed to export data. Please try again.');
    } finally {
      setExporting(false);
    }
  };

  return (
    <>
      <MaterialReactTable table={table} />
    </>
  );
};

const queryClient = new QueryClient();

const SelectedCustomerSalesView: React.FC<SelectedCustomerSalesTableProps> = ({
  customerId,
  dateRange,
}) => (
  <QueryClientProvider client={queryClient}>
    <SelectedCustomerSalesTable customerId={customerId} dateRange={dateRange} />
  </QueryClientProvider>
);

export default SelectedCustomerSalesView;
