import React, { createContext, useState, useContext, ReactNode, useCallback } from 'react';
import { Dayjs } from 'dayjs';

// Define the shape of your context state
interface TransactionContextType {
  customerId: number | null;
  dateRange: [Dayjs | null, Dayjs | null];
  setCustomerId: (id: number | null) => void;
  setDR: (range: [Dayjs | null, Dayjs | null]) => void;
}

// Create the context with a default value
const TransactionContext = createContext<TransactionContextType>({
  customerId: null,
  dateRange: [null, null],
  setCustomerId: (id) => {
    console.log('Default context: Customer ID received', id);
  },
  setDR: (range) => {
    console.log('Default context: Date range received', range);
  },
});

interface CustomerReturnsProviderProps {
  children: ReactNode;
}

export const CustomerReturnsProvider = ({ children }: CustomerReturnsProviderProps) => {
  const [customerId, setCustomerIdState] = useState<number | null>(() => {
    const stored = localStorage.getItem('customerId');
    return stored ? Number(stored) : null;
  });
  const [dateRange, setDateRangeState] = useState<[Dayjs | null, Dayjs | null]>(() => {
    const stored = localStorage.getItem('dateRange');
    return stored ? JSON.parse(stored) : [null, null];
  });

  const setCustomerId = useCallback((id: number | null) => {
    console.log('Customer ID received:', id);
    setCustomerIdState(id);
    if (id) {
      localStorage.setItem('customerId', id.toString());
    } else {
      localStorage.removeItem('customerId');
    }
  }, []);

  const setDR = useCallback((range: [Dayjs | null, Dayjs | null]) => {
    console.log('Date range received:', range);
    setDateRangeState(range);
    localStorage.setItem('dateRange', JSON.stringify(range));
  }, []);

  return (
    <TransactionContext.Provider value={{ customerId, dateRange, setCustomerId, setDR }}>
      {children}
    </TransactionContext.Provider>
  );
};

export const useTransaction = () => {
  return useContext(TransactionContext);
};