import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import {
  MaterialReactTable,
  MRT_Row,
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
} from 'material-react-table';
import {
  Tooltip,
  IconButton,
  Box,
  Button,
  Autocomplete,
  TextField,
  Chip,
  CircularProgress,
} from '@mui/material';
import { useSelector } from 'src/store/Store';
import { AppState } from 'src/store/Store';
import {
  QueryClient,
  QueryClientProvider,
  keepPreviousData,
  useQuery,
} from '@tanstack/react-query';
import axiosClient from 'src/axios_client';
import { Download, DownloadOutlined, Email, PlayArrow, PrintSharp } from '@mui/icons-material';
import { useReactToPrint } from 'react-to-print';
import 'src/styles/reports_print.css';
import {
  Table,
  Typography as TypographyAnt,
  Space,
  Image,
  Button as ButtonAnt,
  Tooltip as TooltipAnt,
  ConfigProvider,
  Row,
  Col,
} from 'antd';
import DateSelector from 'src/components/shared/AntDateSelector';
import dayjs, { Dayjs } from 'dayjs';
import useFormattedNumber from 'src/hooks/useFormattedNumber';
import Link from 'antd/es/typography/Link';
import { useNavigate } from 'react-router';
import { useTransaction } from 'src/contexts/ProductSalesContext';
import LogoDark from 'src/assets/images/logos/dark-logo.svg';
import LogoLight from 'src/assets/images/logos/light-logo.svg';
import * as XLSX from 'xlsx';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { LoadingButton } from '@mui/lab';
import brandImage from 'src/assets/images/brand/rkc.png';

const { Title, Text } = TypographyAnt;
type InventorySummaryApiResponse = {
  data: Array<InventorySummary>;
  pagination: {
    total: number;
    per_page: number;
    current_page: number;
    last_page: number;
    from: number;
    to: number;
  };
};

type InventorySummary = {
  id: number;
  name: string;
  sku: string;
  total_stock_on_hand_sold: string;
  total_sales: string;
  category_name: any;
  unit: any;
  selling_price: any;
  stock_on_hand: any;
};

const PrintableTable = React.forwardRef<
  HTMLDivElement,
  { data: InventorySummary[]; columnsPrint: any[]; dateRange: string }
>(({ data, columnsPrint, dateRange }, ref) => {
  const customizer = useSelector((state: AppState) => state.customizer);

  return (
    <div ref={ref} style={{ margin: '0 20px' }}>
      <Space direction="vertical" size="middle" style={{ display: 'flex', marginTop: '20px' }}>
        <Row>
          <Col span={12}>
            <Space direction="vertical" size={0}>
              <Title level={4} style={{ margin: 0 }}>
                RAEL KITZ CORPORATION - WMS IS
              </Title>
              <Text type="secondary">
                RRF Building, San Miguel Calasiao, Pangasinan
                <br />
                Tel No: +1 (123) 456 7891, +44 (876) 543 2198
              </Text>
            </Space>
          </Col>

          <Col span={12} style={{ left: '100px' }}>
            {customizer.activeMode === 'dark' ? (
              <img src={LogoLight} alt="Logo" height={'40px'} />
            ) : (
              <img src={LogoDark} alt="Logo" height={'40px'} />
            )}
          </Col>
        </Row>

        <div
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}
        >
          <Title level={5} style={{ margin: 0 }}>
            Inventory Summary
          </Title>
          <Text style={{ fontSize: '14px' }}>{dateRange}</Text>
        </div>

        <ConfigProvider
          theme={{
            components: {
              Table: {
                cellFontSizeSM: 10,
                borderColor: '#403C3C',
                headerColor: '#403C3C',
                headerBg: '#ebebeb',
              },
            },
          }}
        >
          <Table
            columns={columnsPrint}
            dataSource={data}
            pagination={false}
            bordered
            size="small"
          />
        </ConfigProvider>

        <div
          style={{
            position: 'fixed',
            bottom: '10px',
            left: '20px',
            right: '20px',
            textAlign: 'center',
            fontSize: '10px',
            color: '#666',
          }}
        >
          {process.env.REACT_APP_NAME} {process.env.REACT_APP_VERSION} by{' '}
          {process.env.REACT_APP_DEVELOPER}
        </div>
      </Space>
    </div>
  );
});

const InventorySummaryTable = () => {
  const customizer = useSelector((state: AppState) => state.customizer);
  const currentTheme = customizer.activeMode;

  const [isDarkMode, setIsDarkMode] = useState(false);
  const [dateRange, setDateRange] = useState<[dayjs.Dayjs | null, dayjs.Dayjs | null]>([
    dayjs().startOf('day'),
    dayjs().endOf('day'),
  ]);
  const dateRangeRef = useRef(dateRange);

  const [categories, setCategories] = useState<Array<{ id: number; name: string }>>([]);
  const [selectedCategories, setSelectedCategories] = useState<number[]>([]);

  // Add this new query to fetch categories
  const { data: categoriesData } = useQuery({
    queryKey: ['categories'],
    queryFn: async () => {
      const response = await axiosClient.get('/categories', {
        params: {
          per_page: 'all',
          search: '',
        },
      });
      return response.data;
    },
  });

  // Update useEffect to set categories when data is fetched
  useEffect(() => {
    if (categoriesData) {
      setCategories(categoriesData.data || []);
    }
  }, [categoriesData]);

  useEffect(() => {
    dateRangeRef.current = dateRange;
  }, [dateRange]);

  const { formatWithPrefix } = useFormattedNumber();

  useEffect(() => {
    setIsDarkMode(currentTheme === 'dark');
  }, [currentTheme]);

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 100,
  });

  const {
    data: { data = [], pagination: meta } = {},
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery<InventorySummaryApiResponse>({
    queryKey: [
      'table-data',
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
      selectedCategories,
    ],
    queryFn: async () => {
      try {
        // Define the base parameters
        const params: Record<string, string> = {
          page: `${pagination.pageIndex + 1}`,
          per_page: `${pagination.pageSize}`,
          filters: JSON.stringify(columnFilters ?? []),
          globalFilter: globalFilter ?? '',
          sorting: JSON.stringify(sorting ?? []),
        };

        // Add selected categories to the query parameters if they are available
        if (selectedCategories.length > 0) {
          params.category_id = selectedCategories.join(',');
        }

        // Add date range filters if available
        if (dateRange[0] && dateRange[1]) {
          params.start_date = dateRange[0].toISOString();
          params.end_date = dateRange[1].toISOString();
        }

        // Fetch the data using axiosClient with the parameters
        const response = await axiosClient.get<InventorySummaryApiResponse>(
          '/inventory-summary-report', // Relative URL path
          { params },
        );

        return response.data;
      } catch (error) {
        console.error('Error fetching product purchase report:', error);
        throw error;
      }
    },

    placeholderData: keepPreviousData,
  });

  const handleRunReport = useCallback(async () => {
    refetch();
  }, [refetch]);

  const navigate = useNavigate();
  const { setProductId, setDR } = useTransaction();

  useEffect(() => {
    console.log('Updated Daterange1', dateRange[0]?.toISOString());
    console.log('Updated Daterange2', dateRange[1]?.toISOString());
  }, [dateRange]);

  const handleViewSalesOfProduct = useCallback(
    (row: any) => {
      setProductId(row.id);
      setDR(dateRangeRef.current);
      navigate('/report-sales-by-selected-product');
    },
    [setProductId, setDR, navigate],
  );

  const columns = useMemo<MRT_ColumnDef<InventorySummary>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'PRODUCT NAME',
        minSize: 50,
        maxSize: 50,
        size: 50,
        enableColumnFilter: false,
        enableSorting: false,
        enableColumnActions: false,
        enableColumnDragging: false,
      },
      {
        accessorKey: 'sku',
        header: 'SKU',
        size: 180,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'category_name',
        header: 'Category',
        size: 50,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'selling_price',
        header: 'Selling Price',
        size: 50,
        enableColumnFilter: false,
        Cell: ({ renderedCellValue, row }) => {
          const value = typeof renderedCellValue === 'number' ? renderedCellValue : 0;
          return <span>{formatWithPrefix(value)}</span>;
        },
      },
      {
        accessorKey: 'unit',
        header: 'Unit',
        size: 50,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'stock_on_hand',
        header: 'PHYSICAL STOCK',
        size: 180,
        enableColumnFilter: false,
      },

      {
        accessorKey: 'stock_total_value',
        header: 'INVENTORY ASSET VALUE',
        grow: false,
        size: 50,
        Cell: ({ renderedCellValue, row }) => {
          const value = typeof renderedCellValue === 'number' ? renderedCellValue : 0;
          return <span>{formatWithPrefix(value)}</span>;
        },
        enableColumnFilter: false,
      },
    ],
    [],
  );

  const [exporting, setExporting] = useState(false);

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: { showColumnFilters: true, density: 'compact' },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    enableColumnOrdering: true,
    enableDensityToggle: false,
    enableRowSelection: true,
    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
        {/* <DateSelector onDateChange={setDateRange} /> */}

        <Autocomplete
          multiple
          id="category-filter"
          options={categories}
          getOptionLabel={(option) => option.name}
          filterOptions={(options, { inputValue }) =>
            options.filter((option) => option.name.toLowerCase().includes(inputValue.toLowerCase()))
          }
          value={categories.filter((cat) => selectedCategories.includes(cat.id))}
          onChange={(event, newValue) => {
            setSelectedCategories(newValue.map((item) => item.id));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Filter Categories"
              placeholder="Select categories"
              sx={{ minWidth: '300px' }}
            />
          )}
        />

        <Tooltip arrow title="Extract Report">
          <Button
            onClick={handleRunReport}
            startIcon={
              <>
                <PlayArrow />
              </>
            }
          >
            Run Report
          </Button>
        </Tooltip>

        <LoadingButton
          onClick={handleExportData}
          startIcon={<DownloadOutlined />}
          loading={exporting}
          loadingPosition="start"
          variant="outlined"
        >
          Export All Data
        </LoadingButton>

        <LoadingButton
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<DownloadOutlined />}
          loading={exporting}
          loadingPosition="start"
          variant="outlined"
          disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
        >
          Export Selected Rows
        </LoadingButton>
      </Box>
    ),
    rowCount: meta?.total ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  const TEMPLATE_PATH = '/assets/etc/DocFormat.xlsx';

  const handleExportRows = async (rows: MRT_Row<InventorySummary>[]) => {
    setExporting(true);
    try {
      // Load the template file
      const workbook = new ExcelJS.Workbook();
      const response = await fetch(TEMPLATE_PATH);
      const arrayBuffer = await response.arrayBuffer();
      await workbook.xlsx.load(arrayBuffer);

      // Access the first worksheet in the template
      const worksheet = workbook.getWorksheet(1);
      if (!worksheet) {
        throw new Error('Worksheet not found in the template file.');
      }

      worksheet.columns = [
        { width: 120 / 7, key: 'sku' },
        { width: 300 / 7, key: 'name' },
        { width: 90 / 7, key: 'category' },
        { width: 80 / 7, key: 'sellingPrice' },
        { width: 50 / 7, key: 'unit' },
        { width: 100 / 7, key: 'stockOnHand' },
        { width: 130 / 7, key: 'stockTotalValue' },
      ];

      const titleCell = worksheet.getCell('F5');
      titleCell.value = 'Inventory Report';
      titleCell.font = { bold: true, size: 14 };

      // Add today's date to H4 dynamically
      const dateCell = worksheet.getCell('F6');
      const today = new Date();
      const formattedDate = today.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
      dateCell.value = formattedDate;
      dateCell.font = { italic: true, size: 12 };

      // const rangeCell = worksheet.getCell('H6');

      // // Function to format dates as "Month XX, XXXX"
      // const formatDate = (isoString: any) => {
      //   const date = new Date(isoString);
      //   return date.toLocaleDateString('en-US', {
      //     month: 'long',
      //     day: 'numeric',
      //     year: 'numeric',
      //   });
      // };

      // // Format the date range
      // const startDate = formatDate(dateRange[0]);
      // const endDate = formatDate(dateRange[1]);

      // // Update the cell value with the formatted dates
      // rangeCell.value = `Issuances from ${startDate} to ${endDate}`;
      // rangeCell.font = { italic: true, size: 12 };

      // Define header and data start rows
      const headerRowIndex = 7;
      const dataStartRowIndex = 8;

      // Insert headers at row 5
      const headerRow = worksheet.getRow(headerRowIndex);
      headerRow.values = [
        // 'ID',
        'SKU',
        'Name',
        'Category',
        'Selling Price',
        'Unit',
        'Stock on Hand',
        'Stock Total Value',
      ];
      headerRow.font = { bold: true };

      // Fetch the imported image
      const imageResponse = await fetch(brandImage);
      const imageBuffer = await imageResponse.arrayBuffer();

      const imageId = workbook.addImage({
        buffer: imageBuffer,
        extension: 'png',
      });

      worksheet.addImage(imageId, {
        tl: {
          col: 0,
          row: 0,
          colOff: (10 * 9600) / 96, // 10px converted to EMU
          rowOff: (10 * 9600) / 96, // 10px converted to EMU
        } as any,
        ext: { width: 175, height: 40 },
        editAs: 'oneCell',
      });

      // Add data starting from row 6
      rows.forEach((row, index) => {
        const data = row.original;
        const currentRow = worksheet.getRow(dataStartRowIndex + index);

        // currentRow.getCell(1).value = data.id;
        currentRow.getCell(1).value = data.sku;
        currentRow.getCell(2).value = data.name;
        currentRow.getCell(3).value = data.category_name;
        currentRow.getCell(4).value = data.selling_price;
        currentRow.getCell(5).value = data.unit;
        currentRow.getCell(6).value = data.stock_on_hand;
        currentRow.getCell(7).value = data.selling_price * data.stock_on_hand; // Compute total value

        currentRow.commit(); // Ensure row is finalized
      });

      // Save the modified Excel file
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, 'InventorySummary.xlsx');
    } catch (error) {
      console.error('Error exporting rows with ExcelJS:', error);
      alert('Failed to export data. Please try again.');
    } finally {
      setExporting(false); // Reset loading state after export completes
    }
  };

  const handleExportData = async () => {
    setExporting(true);
    try {
      const totalRows = meta?.total ?? 0;
      const pageSize = pagination.pageSize;
      const totalPages = Math.ceil(totalRows / pageSize);

      const allData: InventorySummary[] = [];

      // Fetch paginated data
      for (let pageIndex = 0; pageIndex < totalPages; pageIndex++) {
        const params: Record<string, string> = {
          page: `${pageIndex + 1}`,
          per_page: `${pageSize}`,
          filters: JSON.stringify(columnFilters ?? []),
          globalFilter: globalFilter ?? '',
          sorting: JSON.stringify(sorting ?? []),
        };

        if (selectedCategories.length > 0) {
          params.category_id = selectedCategories.join(',');
        }
        if (dateRange[0] && dateRange[1]) {
          params.start_date = dateRange[0].toISOString();
          params.end_date = dateRange[1].toISOString();
        }

        const response = await axiosClient.get<InventorySummaryApiResponse>(
          '/inventory-summary-report',
          { params },
        );
        allData.push(...response.data.data);
      }

      // Reuse handleExportRows logic for data export
      await handleExportRows(
        allData.map((data) => ({ original: data } as MRT_Row<InventorySummary>)),
      );
    } catch (error) {
      console.error('Error exporting data with ExcelJS:', error);
      alert('Failed to export data. Please try again.');
    } finally {
      setExporting(false); // Reset loading state after export completes
    }
  };

  return (
    <>
      <MaterialReactTable table={table} />
    </>
  );
};

const queryClient = new QueryClient();

const InventorySummaryView = () => (
  <QueryClientProvider client={queryClient}>
    <InventorySummaryTable />
  </QueryClientProvider>
);

export default InventorySummaryView;
