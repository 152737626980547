import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import {
    MaterialReactTable,
    useMaterialReactTable,
    type MRT_ColumnDef,
    type MRT_ColumnFiltersState,
    type MRT_PaginationState,
    type MRT_SortingState,
} from 'material-react-table';
import { Tooltip, IconButton, Box, Button } from '@mui/material';
import { useSelector } from 'src/store/Store';
import { AppState } from 'src/store/Store';
import {
    QueryClient,
    QueryClientProvider,
    keepPreviousData,
    useQuery,
} from '@tanstack/react-query';
import axiosClient from 'src/axios_client';
import { Download, Email, PlayArrow, PrintSharp } from '@mui/icons-material';
import { useReactToPrint } from 'react-to-print';
import 'src/styles/reports_print.css';
import {
    Table,
    Typography as TypographyAnt,
    Space,
    Image,
    Button as ButtonAnt,
    Tooltip as TooltipAnt,
    ConfigProvider,
    Row,
    Col,
} from 'antd';
import DateSelector from 'src/components/shared/AntDateSelector';
import dayjs, { Dayjs } from 'dayjs';
import useFormattedNumber from 'src/hooks/useFormattedNumber';
import Link from 'antd/es/typography/Link';
import { useNavigate } from 'react-router';
import { useTransaction } from 'src/contexts/ProductReturnsContext';
import LogoDark from 'src/assets/images/logos/dark-logo.svg';
import LogoLight from 'src/assets/images/logos/light-logo.svg';
import PageContainer from 'src/components/container/PageContainer';
import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb';

type Props = {}
const { Title, Text } = TypographyAnt;
type ObsoleteReturnReportApiResponse = {
    data: Array<ObsoleteReturnReport>;
    pagination: {
        total: number;
        per_page: number;
        current_page: number;
        last_page: number;
        from: number;
        to: number;
    };
};

type ObsoleteReturnReport = {
    id: number;
    product_id: string;
    return_transaction_id: any;
    location_id: any;
    product_transmital_id: any;
    floor: any;
    divider: any;
    serial_numbers: any;
    quantity: any;
    value: any;
    condition: any;
    sku: string;
    quantity_returned: any;
    total_return_amount: any;
    total_price: any;
    total_quantity: any;
};

const BCrumb = [
    {
        to: '/',
        title: 'Home',
    },
    {
        title: 'Obsolete Reports',
    },
];

const PrintableTable = React.forwardRef<
    HTMLDivElement,
    { data: ObsoleteReturnReport[]; columnsPrint: any[]; dateRange: string }
>(({ data, columnsPrint, dateRange }, ref) => {

    const customizer = useSelector((state: AppState) => state.customizer);

    return (<div ref={ref} style={{ margin: '0 20px' }}>
        <Space direction="vertical" size="middle" style={{ display: 'flex', marginTop: '20px' }}>

            <Row>
                <Col span={12}>
                    <Space direction="vertical" size={0}>
                        <Title level={4} style={{ margin: 0 }}>
                            RAEL KITZ CORPORATION - WMS IS
                        </Title>
                        <Text type="secondary">
                            RRF Building, San Miguel Calasiao, Pangasinan
                            <br />
                            Tel No: +1 (123) 456 7891, +44 (876) 543 2198
                        </Text>
                    </Space>
                </Col>

                <Col span={12} style={{ left: '100px' }}>
                    {customizer.activeMode === 'dark' ? (
                        <img src={LogoLight} alt="Logo" height={'40px'} />
                    ) : (
                        <img src={LogoDark} alt="Logo" height={'40px'} />
                    )}
                </Col>
            </Row>

            <div
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}
            >
                <Title level={5} style={{ margin: 0 }}>
                    Sales Report for Product
                </Title>
                <Text style={{ fontSize: '14px' }}>{dateRange}</Text>
            </div>

            <ConfigProvider
                theme={{
                    components: {
                        Table: {
                            cellFontSizeSM: 10,
                            borderColor: '#403C3C',
                            headerColor: '#403C3C',
                            headerBg: '#ebebeb',
                        },
                    },
                }}
            >
                <Table
                    columns={columnsPrint}
                    dataSource={data}
                    pagination={false}
                    bordered
                    size="small"
                    rowKey={(record) => record.id}
                />
            </ConfigProvider>

            <div
                style={{
                    position: 'fixed',
                    bottom: '10px',
                    left: '20px',
                    right: '20px',
                    textAlign: 'center',
                    fontSize: '10px',
                    color: '#666',
                }}
            >
                {process.env.REACT_APP_NAME} {process.env.REACT_APP_VERSION} by{' '}
                {process.env.REACT_APP_DEVELOPER}
            </div>
        </Space>
    </div>)
});

const ObsoleteReturnTable = () => {
    const customizer = useSelector((state: AppState) => state.customizer);
    const currentTheme = customizer.activeMode;

    const [isDarkMode, setIsDarkMode] = useState(false);
    const [dateRange, setDateRange] = useState<[dayjs.Dayjs | null, dayjs.Dayjs | null]>([
        dayjs().startOf('day'),
        dayjs().endOf('day'),
    ]);
    const dateRangeRef = useRef(dateRange);

    useEffect(() => {
        dateRangeRef.current = dateRange;
    }, [dateRange]);

    const { formatWithPrefix } = useFormattedNumber();

    useEffect(() => {
        setIsDarkMode(currentTheme === 'dark');
    }, [currentTheme]);

    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState<MRT_SortingState>([]);
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 100,
    });

    const {
        data: { data = [], pagination: meta } = {},
        isError,
        isRefetching,
        isLoading,
        refetch,
    } = useQuery<ObsoleteReturnReportApiResponse>({
        queryKey: [
            'table-data',
            columnFilters,
            globalFilter,
            pagination.pageIndex,
            pagination.pageSize,
            sorting,
        ],
        queryFn: async () => {
            try {
                // Construct the params object
                const params: { [key: string]: string } = {
                    page: `${pagination.pageIndex + 1}`,
                    per_page: `${pagination.pageSize}`,
                    filters: JSON.stringify(columnFilters ?? []),
                    globalFilter: globalFilter ?? '',
                    sorting: JSON.stringify(sorting ?? []),
                    start_date: dateRange[0] ? dateRange[0].toISOString() : '',
                    end_date: dateRange[1] ? dateRange[1].toISOString() : '',
                };

                // Make the API request with the params object
                const response = await axiosClient.get<ObsoleteReturnReportApiResponse>('/obsolete-reports/view', { params });

                
                return response.data;
            } catch (error) {
                console.error('Error fetching product returns report:', error);
                throw error;
            }
        },
        placeholderData: keepPreviousData,
    });

    const handleRunReport = useCallback(async () => {
        refetch();
    }, [refetch]);

    const navigate = useNavigate();
    const { setProductId, setDR } = useTransaction();

    useEffect(() => {
        console.log('Updated Daterange1', dateRange[0]?.toISOString());
        console.log('Updated Daterange2', dateRange[1]?.toISOString());
    }, [dateRange]);

    const handleViewReturnsOfProduct = useCallback(
        (row: any) => {
            setProductId(row.id);
            setDR(dateRangeRef.current);
            navigate('/report-returns-by-selected-product');
        },
        [setProductId, setDR, navigate],
    );

    const columns = useMemo<MRT_ColumnDef<ObsoleteReturnReport>[]>(
        () => [
            // {
            //   accessorKey: 'id',
            //   header: 'DB ID',
            //   minSize: 50,
            //   maxSize: 50,
            //   size: 50,
            //   enableColumnFilter: false,
            //   enableSorting: false,
            //   enableColumnActions: false,
            //   enableColumnDragging: false,
            // },
            {
                accessorKey: 'product_name',
                header: 'Product Name',
                size: 180,
                enableColumnFilter: false,
                Cell: ({ renderedCellValue, row }) => {
                    return (
                        <>
                            {renderedCellValue}
                        </>
                        // <Link onClick={() => handleViewReturnsOfProduct(row.original)}>{renderedCellValue}</Link>
                    );
                },
            },
            {
                accessorKey: 'product_sku',
                header: 'SKU',
                size: 180,
                enableColumnFilter: false,
            },
            {
                accessorKey: 'total_quantity',
                header: 'Obsolete Count',
                grow: false,
                size: 50,
                Cell: ({ renderedCellValue, row }) => {
                    return <span>{renderedCellValue}</span>;
                },
                enableColumnFilter: false,
            },
            {
                id: 'total_price',
                header: 'Total Obsolete Amount',
                size: 100,
                Cell: ({ renderedCellValue, row }) => {

                    console.log(row.original);

                    const value = typeof row.original.total_price === 'number' ? row.original.total_price : 0;
                    return <span>{formatWithPrefix(value)}</span>;
                },
                enableColumnFilter: false,
            },
        ],
        [],
    );

    const printableTableRef = useRef<HTMLDivElement>(null);

    const handlePrint = useReactToPrint({
        content: () => printableTableRef.current,
        pageStyle: `
    @media print {
      @page {
        size: landscape;
        margin: 0;
      }
      body {
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
      }
      html, body {
        height: 100%;
        margin: 0 !important;
        padding: 0 !important;
        overflow: hidden;
      }
    }
  `,
    });

    const table = useMaterialReactTable({
        columns,
        data,
        initialState: { showColumnFilters: true, density: 'compact' },
        manualFiltering: true,
        manualPagination: true,
        manualSorting: true,
        muiToolbarAlertBannerProps: isError
            ? {
                color: 'error',
                children: 'Error loading data',
            }
            : undefined,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        enableColumnOrdering: true,
        enableDensityToggle: false,
        renderTopToolbarCustomActions: () => (
            <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
                <DateSelector onDateChange={setDateRange} />

                <Tooltip arrow title="Extract Report">
                    <Button
                        onClick={handleRunReport}
                        startIcon={
                            <>
                                <PlayArrow />
                            </>
                        }
                    >
                        Run Report
                    </Button>
                </Tooltip>

                <Tooltip arrow title="Print">
                    <IconButton onClick={handlePrint}>
                        <PrintSharp />
                    </IconButton>
                </Tooltip>

                <Tooltip arrow title="Download as Document">
                    <span>
                        <IconButton disabled>
                            <Download />
                        </IconButton>
                    </span>
                </Tooltip>

                <Tooltip arrow title="Send to Email">
                    <span>
                        <IconButton disabled>
                            <Email />
                        </IconButton>
                    </span>
                </Tooltip>
            </Box>
        ),
        rowCount: meta?.total ?? 0,
        state: {
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            sorting,
        },
    });

    const columnsPrint = useMemo(
        () => [
            // {
            //   title: 'DB ID',
            //   dataIndex: 'id',
            //   key: 'id',
            // },
            {
                title: 'Product Name',
                dataIndex: 'product_name',
                key: 'product_name',
            },
            {
                title: 'SKU',
                dataIndex: 'product_sku',
                key: 'product_sku',
            },
            {
                title: 'Obsolete Count',
                dataIndex: 'total_quantity',
                key: 'total_quantity',
            },
            {
                title: 'Total Obsolete Amount',
                dataIndex: 'total_price',
                key: 'total_price',
                render: (text: number) => (
                    <>
                        <span style={{ textAlign: 'right', display: 'block' }}>{formatWithPrefix(text)}</span>
                    </>
                ),
            },
        ],
        [formatWithPrefix],
    );

    const formattedPrintData = useMemo(() => {
        return data.map((product) => ({
            ...product,
            total_return_amount: product.total_return_amount,
        }));
    }, [data, formatWithPrefix]);

    const formattedDateRange = useMemo(() => {
        if (dateRange[0] && dateRange[1]) {
            const startDate = dateRange[0].format('MMMM DD, YYYY');
            const endDate = dateRange[1].format('MMMM DD, YYYY');
            return `[${startDate} - ${endDate}]`;
        }
        return '';
    }, [dateRange]);

    return (
        <>
            <MaterialReactTable table={table} />

            <div style={{ display: 'none' }}>
                <PrintableTable
                    ref={printableTableRef}
                    data={formattedPrintData}
                    columnsPrint={columnsPrint}
                    dateRange={formattedDateRange}
                />
            </div>
        </>
    );
};

const queryClient = new QueryClient();

function ReturnsObsolete({ }: Props) {
    return (

        <PageContainer title="RKCWMS - Returns By Product Page" description="this is Sample page">
            {/* breadcrumb */}
            <Breadcrumb title="Returns By Product Page" items={BCrumb} />
            {/* end breadcrumb */}
            <Box>
                <QueryClientProvider client={queryClient}>
                    <ObsoleteReturnTable />
                </QueryClientProvider>
            </Box>
        </PageContainer>

    )
}

export default ReturnsObsolete