import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Divider,
  Paper,
  Grid,
} from '@mui/material';
import axiosClient from 'src/axios_client';
import dayjs from 'dayjs';
import { Input, Popconfirm, Popover, Steps, message, ConfigProvider } from 'antd';
import { theme } from 'antd/lib';
import {
  LoadingOutlined,
  SmileOutlined,
  SolutionOutlined,
  UserOutlined,
  FormOutlined,
} from '@ant-design/icons';
import { useSelector, AppState } from 'src/store/Store';
import DashboardCard from '../../dashboard/components/DashboardCard';
import ReactToPrint from 'react-to-print';
import { LogoDark, LogoLight } from 'src/globals/LogoExports';

interface Product {
  id: number;
  name: string;
  brand: string;
  model: string;
  quantity: number;
  status: number;
  serial_number: string;
  reference_number: string;
}

interface Transaction {
  id: number;
  reference_number: any;
  image: string | null;
  notes: string;
  pickup_by: string;
  destination: number;
  supply_center: any;
  created_by: {
    id: number;
    name: string;
  };
  checked_by: any;
  received_by: any;
  checked_at: any;
  received_at: any;
  received_note: string;
  checked_note: string;
  created_at: string;
  updated_at: string;
  products: Product[];
}

interface TransmittalBodyProps {
  transactionId: string | null;
  userlevel: string | undefined;
  onAcknowledge: () => void;
}

interface ErrorResponse {
  message: string;
  error: string;
}

const TransmittalBody: FC<TransmittalBodyProps> = ({ transactionId, userlevel, onAcknowledge }) => {
  const [transaction, setTransaction] = useState<Transaction | null>(null);
  const [note, setNote] = useState('');
  const [isAcknowledged, setIsAcknowledged] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();

  // Use useSelector at the top level
  const customizer = useSelector((state: AppState) => state.customizer);
  const currentTheme = customizer.activeMode;

  const [isDarkMode, setIsDarkMode] = useState(currentTheme === 'dark');
  const { defaultAlgorithm, darkAlgorithm } = theme;

  useEffect(() => {
    setIsDarkMode(currentTheme === 'dark');
  }, [currentTheme]);

  const fetchData = useCallback(async () => {
    if (!transactionId) {
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);
      const response = await axiosClient.get(`te_transactions/${transactionId}`);
      const transactionData = response.data.data;
      setTransaction(transactionData);

      // Check if the current user has already acknowledged the transaction
      if (userlevel === 'checker' && transactionData.checked_by?.id) {
        setIsAcknowledged(true);
      } else if (userlevel === 'stockman' && transactionData.received_by?.id) {
        setIsAcknowledged(true);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      messageApi.open({
        type: 'error',
        content: `Contact your system administrator`,
        className: 'robotoFont',
        style: {
          marginTop: '20vh',
        },
      });
    } finally {
      setIsLoading(false);
    }
  }, [transactionId, userlevel, messageApi]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const acknowledge = async () => {
    if (!transactionId) return;

    try {
      const response = await axiosClient.post(`/te_transactions/${transactionId}/acknowledge`, {
        userlevel: userlevel,
        note: note,
      });
      console.log('Acknowledgment successful', response.data);
      messageApi.open({
        type: 'success',
        content: `Acknowledgment successful`,
        className: 'robotoFont',
        style: {
          marginTop: '20vh',
        },
      });
      setIsAcknowledged(true);
      onAcknowledge(); // Call the callback to refresh the parent component
      fetchData(); // Refresh the transaction data
    } catch (error) {
      console.error('Acknowledgment failed', error);
      const errorMessage =
        (error as { response?: { data?: ErrorResponse } }).response?.data?.error ||
        'An unexpected error occurred';
      messageApi.open({
        type: 'error',
        content: `Acknowledgment unsuccessful: ${errorMessage}`,
        className: 'robotoFont',
        style: {
          marginTop: '20vh',
        },
      });
    }
  };

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  if (!transaction) {
    return <Typography>No transaction data available.</Typography>;
  }

  const getCurrentStep = () => {
    if (transaction.received_by?.id) return 3;
    if (transaction.checked_by?.id) return 2;
    if (transaction.created_by?.id) return 1;
    return 0;
  };

  const getStepStatus = (step: number) => {
    const currentStep = getCurrentStep();
    if (step < currentStep) return 'finish';
    if (step === currentStep) return 'process';
    return 'wait';
  };

  const formatDate = (date: string | null) => {
    return date ? dayjs(date).format('MMMM D, YYYY h:mm A') : 'Pending';
  };

  const getName = (user: { id: number | null; name: string | null } | null) => {
    return user?.name || 'Pending';
  };

  // const componentRef = useRef(null);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        p: 4,
        bgcolor: 'background.paper',
        borderRadius: 1,
        boxShadow: 3,
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} md={9} id={'transactionData'}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              padding: '10px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                mb: 4,
              }}
            >
              <Box>
                <Box sx={{ width: '250px', marginLeft: '-36px', marginBottom: '5px' }}>
                  {customizer.activeMode === 'dark' ? (
                    <LogoLight height={'auto'} />
                  ) : (
                    <LogoDark height={'auto'} />
                  )}

                  {/* <RECEIPT
              style={{
                fill: theme.palette.mode === 'dark' ? '#ffffff' : '#000000', // Change fill color based on theme mode
              }}
              className="printable-logo"
            /> */}
                </Box>
                <Typography
                  variant="h6"
                  component="p"
                  sx={{ color: 'text.primary', fontWeight: 'bold' }}
                >
                  RAEL KITZ CORPORATION - WMS IS
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  RRF Building
                  <br />
                  San Miguel Calasiao, Pangasinan
                  <br />
                  Tel No: +1 (123) 456 7891, +44 (876) 543 2198
                </Typography>
              </Box>

              <Box sx={{ textAlign: 'right' }}>
                <Typography
                  variant="body1"
                  component="p"
                  sx={{ color: 'text.primary', fontWeight: 'bold' }}
                >
                  TRANSACTION #: {transaction.reference_number}
                </Typography>
                <Typography variant="body1" sx={{ color: 'text.primary', fontWeight: 'bold' }}>
                  <strong>Originating Station:</strong> {transaction.created_by.name}
                </Typography>
                <Typography variant="body1" sx={{ color: 'text.primary', fontWeight: 'bold' }}>
                  <strong>Destination:</strong> {transaction.supply_center.name}
                </Typography>

                <Typography variant="body1" sx={{ color: 'text.primary', fontWeight: 'bold' }}>
                  <strong>Transfer Date:</strong> {formatDate(transaction.created_at)}
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  sx={{ color: 'text.primary', fontWeight: 'bold' }}
                >
                  HAULER: {transaction.pickup_by}
                </Typography>
              </Box>
            </Box>
            <Table
              className="printTable"
              sx={{
                width: '100%',
                textAlign: 'left',
                mb: 4,
                border: '1px solid rgba(224, 224, 224, 1)',
                borderCollapse: 'collapse',
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      backgroundColor: '#eaeef3',
                      border: '1px solid rgba(224, 224, 224, 1)',
                      padding: '8px',
                      textAlign: 'left',
                      fontWeight: 'bold',
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: '#eaeef3',
                      border: '1px solid rgba(224, 224, 224, 1)',
                      padding: '8px',
                      textAlign: 'left',
                      fontWeight: 'bold',
                    }}
                  >
                    Brand
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: '#eaeef3',
                      border: '1px solid rgba(224, 224, 224, 1)',
                      padding: '8px',
                      textAlign: 'left',
                      fontWeight: 'bold',
                    }}
                  >
                    Model
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: '#eaeef3',
                      border: '1px solid rgba(224, 224, 224, 1)',
                      padding: '8px',
                      textAlign: 'left',
                      fontWeight: 'bold',
                    }}
                  >
                    Quantity
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: '#eaeef3',
                      border: '1px solid rgba(224, 224, 224, 1)',
                      padding: '8px',
                      textAlign: 'left',
                      fontWeight: 'bold',
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: '#eaeef3',
                      border: '1px solid rgba(224, 224, 224, 1)',
                      padding: '8px',
                      textAlign: 'left',
                      fontWeight: 'bold',
                    }}
                  >
                    Serial Number
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: '#eaeef3',
                      border: '1px solid rgba(224, 224, 224, 1)',
                      padding: '8px',
                      textAlign: 'left',
                      fontWeight: 'bold',
                    }}
                  >
                    Reference Number
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transaction.products.map((product) => (
                  <TableRow key={product.id}>
                    <TableCell
                      sx={{
                        border: '1px solid rgba(224, 224, 224, 1)',
                        padding: '8px',
                      }}
                    >
                      {product.name}
                    </TableCell>
                    <TableCell
                      sx={{
                        border: '1px solid rgba(224, 224, 224, 1)',
                        padding: '8px',
                      }}
                    >
                      {product.brand}
                    </TableCell>
                    <TableCell
                      sx={{
                        border: '1px solid rgba(224, 224, 224, 1)',
                        padding: '8px',
                      }}
                    >
                      {product.model}
                    </TableCell>
                    <TableCell
                      sx={{
                        border: '1px solid rgba(224, 224, 224, 1)',
                        padding: '8px',
                      }}
                    >
                      {product.quantity}
                    </TableCell>
                    <TableCell
                      sx={{
                        border: '1px solid rgba(224, 224, 224, 1)',
                        padding: '8px',
                      }}
                    >
                      {product.status === 0 ? 'DAMAGED' : 'GOOD'}
                    </TableCell>
                    <TableCell
                      sx={{
                        border: '1px solid rgba(224, 224, 224, 1)',
                        padding: '8px',
                      }}
                    >
                      {product.serial_number}
                    </TableCell>
                    <TableCell
                      sx={{
                        border: '1px solid rgba(224, 224, 224, 1)',
                        padding: '8px',
                      }}
                    >
                      {product.reference_number}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <Grid item xs={12} sx={{ textAlign: 'center' }} mt={1}>
              <Divider sx={{ borderColor: 'black' }} />
            </Grid>

            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Note: {transaction.notes}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Steps
              progressDot
              current={getCurrentStep()}
              direction="vertical"
              items={[
                {
                  title: 'Created Obsolete Item Return',
                  description: (
                    <>
                      <div>{getName(transaction.created_by)}</div>
                      <div>{formatDate(transaction.created_at)}</div>
                    </>
                  ),
                },
                {
                  title: (
                    <>
                      <Popover
                        content={
                          transaction.checked_note === '' || transaction.checked_note == null
                            ? 'Notes will be here'
                            : transaction.checked_note
                        }
                      >
                        Cross-Checked by Checker
                      </Popover>
                    </>
                  ),
                  description: (
                    <>
                      <div>{getName(transaction.checked_by)}</div>
                      <div>{formatDate(transaction.checked_at)}</div>
                    </>
                  ),
                },
                {
                  title: (
                    <>
                      <Popover
                        content={
                          transaction.received_note === '' || transaction.received_note == null
                            ? 'Notes will be here'
                            : transaction.received_note
                        }
                      >
                        Received by Stockman
                      </Popover>
                    </>
                  ),
                  description: (
                    <>
                      <div>{getName(transaction.received_by)}</div>
                      <div>{formatDate(transaction.received_at)}</div>
                    </>
                  ),
                },
                {
                  title: 'Confirmation',
                  description: transaction.received_at ? 'Completed' : 'Pending',
                },
              ]}
            />
            {(userlevel === 'checker' ||
              userlevel === 'stockman' ||
              userlevel === 'superadmin') && (
              <Popconfirm
                title="Acknowledge Transmittal"
                description={
                  <>
                    <p>Are you sure you want to acknowledge this transmittal?</p>
                    <Input.TextArea
                      placeholder="Add a note (optional)"
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                      rows={4}
                    />
                  </>
                }
                onConfirm={acknowledge}
                okText="Yes"
                cancelText="No"
                placement="right"
              >
                <Button
                  variant="outlined"
                  disabled={isAcknowledged}
                  style={{
                    width: '100%', // Make the button full-width in lg-3
                    height: '50px', // Set the desired height
                    margin: '0 auto',
                  }}
                >
                  {isAcknowledged ? 'Acknowledged' : 'Acknowledge'}
                </Button>
              </Popconfirm>
            )}

            <ReactToPrint
              trigger={() => (
                <Button variant="contained" size="small" color="primary">
                  Print
                </Button>
              )}
              content={() => document.getElementById('transactionData')}
              pageStyle={`
                @media print {
                  body {
                    -webkit-print-color-adjust: exact;
                    margin: 20mm;
                    padding: 50px;
                  }
                  @page {
                    margin: 20mm;
                  }
                  .printTable th,
                  .printTable td {
                    padding: 2px;
                  }
                  header, footer {
                    display: none;
                  }
                  .pageBreak {
                    page-break-after: always;
                  }
                  .fontSize8pt {
                    font-size: 8pt !important;
                  }
                  tbody tr:not(:last-child) td {
                    border-bottom: 1px solid #acacac;
                  }
                  .printable-logo {
                    fill: #000000 !important; /* Ensure the logo prints in black */
                  }
                }
              `}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TransmittalBody;
