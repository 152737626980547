import { Add, RefreshOutlined } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  Container,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Skeleton,
  Tab,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { IconMenu2 } from '@tabler/icons';
import {
  QueryClient,
  QueryClientProvider,
  keepPreviousData,
  useQuery,
} from '@tanstack/react-query';
import { Image } from 'antd';
import { stubTrue } from 'lodash';
import {
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_SortingState,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import ReactVirtualizedAutoSizer, { Size } from 'react-virtualized-auto-sizer';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import axiosClient from 'src/axios_client';
import PageContainer from 'src/components/container/PageContainer';
import Scrollbar from 'src/components/custom-scroll/Scrollbar';
import {
  AdjustmentsTable,
  IssuanceTable,
  PurchasesTable,
  ReturnsTable,
  SerialNumberTable,
} from 'src/globals/MaterialTables';
import { formatCurrency } from 'src/globals/NumericFormat';
import { formatDate } from 'src/globals/PublicFunctions';
import { AppState } from 'src/store/Store';
import ProductUpdateDialog from './ProductUpdate';

type Props = {};
type HandleClickType = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => void;
interface RowProps extends ListChildComponentProps {
  data: any;
  selectedIndex: number | null;
  index: any;
  style: any;
  handleClick: HandleClickType;
}

interface TransactionData {
  adjustment_status: any;
  created_at: any;
  id: any;
  image: any;
  products: any;
  reason: any;
  reference_number: any;
  transaction_number: any;
  updated_at: any;
}

interface ProductData {
  id: any;
  transaction_history: TransactionData[];
  name: any;
  description: any;
  sku: any;
  location: any;
  unit: any;
  created_by: any;
  has_serial: any;
  manufacturer_name: any;
  brand_name: any;
  supplier_name: any;
  category_name: any;
  buying_price: any;
  selling_price: any;
  image: any;
  quantity: any;
}
type ProductApiResponse = {
  data: Array<ProductData>;
  pagination: {
    total: number;
    per_page: number;
    current_page: number;
    last_page: number;
    from: number;
    to: number;
  };
};

interface ProductComponentProps {
  productData: {
    id: number;
    image: string;
  };
  refreshProductData: () => void;
}

const Row: React.FC<RowProps> = memo(({ data, index, style, selectedIndex, handleClick }) => {
  const item = data[index];
  const status = data[index].adjustment_status;
  const statusText =
    status >= 1 && status <= 3 ? 'Pending' : status >= 4 && status <= 6 ? 'Approved' : 'Unknown';

  return (
    <div style={style}>
      <ListItemButton
        onClick={(event) => handleClick(event, index)}
        sx={{
          mb: 0,
          py: 2,
          px: 3,
        }}
        selected={selectedIndex === index}
      >
        <ListItemText
          primary={
            <>
              <Grid container>
                <Grid item flexGrow={1}>
                  <Typography variant="subtitle2" fontWeight={600}>
                    Item name
                  </Typography>
                </Grid>
              </Grid>
            </>
          }
          sx={{ my: 0 }}
        />
      </ListItemButton>
    </div>
  );
});

const MaterialTable = () => {
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  const navigate = useNavigate();
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const {
    data: { data = [], pagination: meta } = {},
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery<ProductApiResponse>({
    queryKey: [
      'table-data',
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    queryFn: async () => {
      try {
        const params = {
          page: `${pagination.pageIndex + 1}`,
          per_page: `${pagination.pageSize}`,
          filters: JSON.stringify(columnFilters ?? []),
          globalFilter: globalFilter ?? '',
          sorting: JSON.stringify(sorting ?? []),
        };

        const response = await axiosClient.get<ProductApiResponse>(
          '/products', // Use the relative path
          { params }, // Pass query parameters as params
        );

        console.log(response.data); // You can keep this for debugging purposes

        return response.data;
      } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
      }
    },

    placeholderData: keepPreviousData,
  });
  const columns = useMemo<MRT_ColumnDef<ProductData>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Product Name',
        size: 80,
      },
    ],
    [],
  );
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const table = useMaterialReactTable({
    columns,
    data,
    layoutMode: 'grid',
    defaultColumn: {
      minSize: 20, //allow columns to get smaller than default
      maxSize: 9001, //allow columns to get larger than default
      size: 180, //make columns wider by default
    },
    initialState: {
      columnVisibility: { sku: false },
      showColumnFilters: true,
    },
    enableRowSelection: true,
    enableMultiRowSelection: false,
    positionToolbarAlertBanner: 'none',
    onRowSelectionChange: setRowSelection,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    enableColumnOrdering: true,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        const toggleHandler = row.getToggleSelectedHandler();
        toggleHandler(event);
        navigate(`/product-master/view/${row.original.sku}`);
      },
      sx: {
        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
      },
    }),
    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: 'flex', gap: '1rem', p: '4px', alignItems: 'center' }}>
        <Tooltip arrow title="Refresh Data">
          <IconButton onClick={() => refetch()}>
            <RefreshOutlined />
          </IconButton>
        </Tooltip>
        <Button
          variant="contained"
          startIcon={<Add />}
          color="primary"
          to="/product-entry"
          component={Link}
        >
          New
        </Button>
      </Box>
    ),

    rowCount: meta?.total ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      rowSelection,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  return <MaterialReactTable table={table} />;
};

const MainComponent = () => {
  const { id } = useParams();

  const customizer = useSelector((state: AppState) => state.customizer);
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const [isMobileSidebarOpen, setMobileSidebarOpen] = useState(false);
  const drawerWidth = 380;
  const [loadingData, setLoadingData] = useState(false);
  const [panelValue, setPanelValue] = React.useState('1');
  const [productData, setProductData] = useState<ProductData>();

  const refreshProductData = () => {
    setLoadingData(true);
    axiosClient
      .get(`/products/view/${id}`)
      .then(({ data, status }) => {
        if (status == 200) {
          setLoadingData(false);
        }
        setProductData(data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setLoadingData(true);
    axiosClient
      .get(`/products/view/${id}`)
      .then(({ data, status }) => {
        if (status == 200) {
          setLoadingData(false);
        }
        setProductData(data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setPanelValue(newValue);
  };

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <PageContainer title="RKCWMS - Product Adjustment Page" description="this is Sample page">
      <Container sx={{ maxWidth: { md: '1500px' } }}>
        <Card
          sx={{ display: 'flex', p: 0 }}
          elevation={customizer.isCardShadow ? 9 : 0}
          variant={!customizer.isCardShadow ? 'outlined' : undefined}
        >
          <Drawer
            open={isMobileSidebarOpen}
            onClose={() => setMobileSidebarOpen(false)}
            variant={lgUp ? 'permanent' : 'temporary'}
            sx={{
              width: drawerWidth,
              flexShrink: 0,

              zIndex: lgUp ? 0 : 1,
              [`& .MuiDrawer-paper`]: { position: 'relative', overflow: 'hidden' },
            }}
          >
            <Box display="flex" alignItems="center" p={2}>
              <ListItem dense disableGutters>
                <ListItemText primary={<Typography variant="h5">Product Lists</Typography>} />
              </ListItem>
            </Box>
            <MaterialTable />

            {/* <AutoSizer>
                            {({ height, width }: Size) => (
                                <FixedSizeList
                                    height={height} // Adjust based on your container's height
                                    itemCount={data.length}
                                    itemSize={90}
                                    width={width}
                                >
                                    {({ index, style }) => (
                                        <Row
                                            data={data}
                                            index={index}
                                            style={style}
                                            selectedIndex={selectedIndex}
                                            handleClick={handleListItemClick}
                                        />
                                    )}
                                </FixedSizeList>
                            )}
                        </AutoSizer> */}
          </Drawer>
          <Box flexGrow={1}>
            <Box display="flex" alignItems="center" p={2}>
              <Box
                sx={{
                  display: { xs: 'block', md: 'block', lg: 'none' },
                  mr: '10px',
                }}
              >
                <IconMenu2 stroke={1.5} onClick={() => setMobileSidebarOpen(true)} />
              </Box>
              <ListItem dense disableGutters>
                <ListItemText
                  primary={<Typography variant="h5">{productData?.name}</Typography>}
                  secondary={<Typography variant="subtitle2">{productData?.sku}</Typography>}
                />
              </ListItem>
            </Box>
            <Divider />
            <Scrollbar sx={{ height: 'calc(100vh - 170px)', flexGrow: 1 }}>
              <Container>
                <Box
                  sx={{ maxWidth: { xs: 320, sm: 480, md: '100%' }, bgcolor: 'background.paper' }}
                >
                  <TabContext value={panelValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="lab API tabs example"
                      >
                        <Tab label="Overview" value="1" />
                        <Tab label="Purchases" value="2" />
                        <Tab label="Returns" value="3" />
                        <Tab label="Adjustments" value="4" />
                        <Tab label="Issuances" value="5" />

                        {productData?.has_serial && <Tab label="Serial Numbers" value="6" />}
                      </TabList>
                    </Box>
                    <TabPanel value="1">
                      {loadingData && (
                        <Box flexGrow={2}>
                          <Container sx={{ mt: 5 }}>
                            <Grid container spacing={2}>
                              <Grid item xs={3}>
                                <Skeleton variant="rectangular" height={19} />
                              </Grid>
                              <Grid item xs={9}>
                                <Skeleton variant="rectangular" height={19} />
                              </Grid>
                              <Grid item xs={3}>
                                <Skeleton variant="rectangular" height={19} />
                              </Grid>
                              <Grid item xs={9}>
                                <Skeleton variant="rectangular" height={19} />
                              </Grid>
                              <Grid item xs={3}>
                                <Skeleton variant="rectangular" height={19} />
                              </Grid>
                              <Grid item xs={9}>
                                <Skeleton variant="rectangular" height={19} />
                              </Grid>
                              <Grid item xs={3}>
                                <Skeleton variant="rectangular" height={19} />
                              </Grid>
                              <Grid item xs={9}>
                                <Skeleton variant="rectangular" height={19} />
                              </Grid>
                              <Grid item xs={3}>
                                <Skeleton variant="rectangular" height={19} />
                              </Grid>
                              <Grid item xs={9}>
                                <Skeleton variant="rectangular" height={19} />
                              </Grid>
                              <Grid item xs={3}>
                                <Skeleton variant="rectangular" height={19} />
                              </Grid>
                              <Grid item xs={9}>
                                <Skeleton variant="rectangular" height={19} />
                              </Grid>
                              <Grid item xs={3}>
                                <Skeleton variant="rectangular" height={19} />
                              </Grid>
                              <Grid item xs={9}>
                                <Skeleton variant="rectangular" height={19} />
                              </Grid>
                              <Grid item xs={3}>
                                <Skeleton variant="rectangular" height={19} />
                              </Grid>
                              <Grid item xs={9}>
                                <Skeleton variant="rectangular" height={19} />
                              </Grid>
                            </Grid>
                          </Container>
                        </Box>
                      )}
                      {!loadingData && productData && (
                        <Box flexGrow={2}>
                          <Container sx={{ mt: 5 }}>
                            <Grid container>
                              <Grid item xs={12} md={9}>
                                <Grid container rowSpacing={{ xs: 0, sm: 2 }}>
                                  <Grid item xs={12} sm={3} fontWeight={600}>
                                    Item Name
                                  </Grid>
                                  <Grid item xs={12} sm={9}>
                                    {productData.name}
                                  </Grid>
                                  <Grid item xs={12} sm={3} fontWeight={600}>
                                    Brand
                                  </Grid>
                                  <Grid item xs={12} sm={9}>
                                    {productData.brand_name !== null ? (
                                      productData.brand_name
                                    ) : (
                                      <Chip variant="filled" label="unbranded" color="error" />
                                    )}
                                  </Grid>
                                  <Grid item xs={12} sm={3} fontWeight={600}>
                                    Item Type
                                  </Grid>
                                  <Grid item xs={12} sm={9}>
                                    {productData.category_name}
                                  </Grid>
                                  <Grid item xs={12} sm={3} fontWeight={600} mt={{ xs: 1, sm: 0 }}>
                                    SKU
                                  </Grid>
                                  <Grid item xs={12} sm={9}>
                                    {productData.sku}
                                  </Grid>
                                  <Grid item xs={12} sm={3} fontWeight={600} mt={{ xs: 1, sm: 0 }}>
                                    Description
                                  </Grid>
                                  <Grid item xs={12} sm={9}>
                                    {productData.description}
                                  </Grid>
                                  {/* <Grid item xs={12} sm={3} fontWeight={600} mt={{ xs: 1, sm: 0 }}>
                                    Supplier
                                  </Grid>
                                  <Grid item xs={12} sm={9}>
                                    {productData.supplier_name}
                                  </Grid> */}
                                  <Grid item xs={12} sm={3} fontWeight={600} mt={{ xs: 1, sm: 0 }}>
                                    Unit
                                  </Grid>
                                  <Grid item xs={12} sm={9}>
                                    {productData.unit}
                                  </Grid>
                                  <Grid item xs={12} sm={3} fontWeight={600} mt={{ xs: 1, sm: 0 }}>
                                    Quantity
                                  </Grid>
                                  <Grid item xs={12} sm={9}>
                                    {productData.quantity}
                                  </Grid>
                                  <Grid item xs={12} sm={3} fontWeight={600} mt={{ xs: 1, sm: 0 }}>
                                    Created Source
                                  </Grid>
                                  <Grid item xs={12} sm={9}>
                                    {productData.created_by}
                                  </Grid>
                                  <Grid item xs={12} mt={{ xs: 1, sm: 0 }}>
                                    <Divider />
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <Box
                                  display={'flex'}
                                  flexDirection={'column'}
                                  alignItems={'center'}
                                  gap={2}
                                >
                                  <Avatar
                                    variant="rounded"
                                    sx={{
                                      height: { xs: 'auto', md: '160px' },
                                      width: { xs: '100%', md: '160px' },
                                    }}
                                  >
                                    <img
                                      alt=""
                                      src={`${productData?.image}?t=${Date.now()}`} // Add timestamp to force refresh
                                      height={'auto'}
                                      width={'100%'}
                                    />
                                  </Avatar>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleDialogOpen}
                                  >
                                    Update Image
                                  </Button>
                                  {/* Dialog Component for Updating Image */}
                                  <ProductUpdateDialog
                                    open={dialogOpen}
                                    onClose={handleDialogClose}
                                    productId={productData.id}
                                    refreshProductData={refreshProductData}
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                          </Container>
                        </Box>
                      )}
                    </TabPanel>
                    <TabPanel value="2">
                      {!(loadingData && productData) && <PurchasesTable />}
                    </TabPanel>
                    <TabPanel value="3">
                      {!(loadingData && productData) && <ReturnsTable />}
                    </TabPanel>
                    <TabPanel value="4">
                      {!(loadingData && productData) && <AdjustmentsTable />}
                    </TabPanel>
                    <TabPanel value="5">
                      {!(loadingData && productData) && <IssuanceTable />}
                    </TabPanel>

                    <TabPanel value="6">
                      {!(loadingData && productData?.has_serial) && <SerialNumberTable />}
                    </TabPanel>
                  </TabContext>
                </Box>
              </Container>
            </Scrollbar>
          </Box>
        </Card>
      </Container>
    </PageContainer>
  );
};
const queryClient = new QueryClient();

function ProductDetails({}: Props) {
  return (
    <QueryClientProvider client={queryClient}>
      <MainComponent />
    </QueryClientProvider>
  );
}

export default ProductDetails;
